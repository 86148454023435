import '../scss/style.scss';

const bootstrap = require('../../node_modules/bootstrap/dist/js/bootstrap.esm.js');
import './totop';

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
Array.from(tooltipTriggerList).map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));


$.fn.activeLabel = function() {
  return $(this).each(function() {
    $(this).parent().find('label').toggleClass('active', !!$(this).val());
  });
};
$(document).on('fezar:draw fezar:redraw', '#footer-form, #modal-form, #modal-form-event', function (e) {
  const id = e.target.id;

  $('.form-control').activeLabel().on('focus', function() {
    $(this).parent().find('label').addClass('active');
  }).on('blur', function() {
    $(this).parent().find('label').toggleClass('active', !!$(this).val());
  });

  if ($('.has-error').length > 0) {
    $('.has-error>.form-control').focus();
  }

  // $('.maskedphone').inputmask({
  //   showMaskOnHover: false,
  //   mask: '+7(999)-999-99-99',
  // });

  $('#modal-form-button').prop('disabled', !$('#fezar-id-5').is(':checked'));

  $('#fezar-id-5').change(function() {
    if ($(this).is(':checked')) {
      $('#modal-form-button').prop('disabled', false);
    } else {
      $('#modal-form-button').prop('disabled', true);
    }
  });

  $('button', '#' + id).on('click', function(e) {
    e.preventDefault();
    grecaptcha.ready(function() {
      grecaptcha.execute(window.GCaptchaCode, {action: 'number'})
          .then(function(token) {
            $('[name=captcha]', '#' + id).val(token);
            $('#' + id).submit();
          });
    });
  });
});
